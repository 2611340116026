body {
  margin: 0;
  padding: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("assets/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  font-family: sans-serif;
}